.component {
  background: var(--fastdo-primary-background-color);
  width: 100%;
  min-height: 10rem;
  padding: 2rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.component p {
  max-width: 500px;
  text-align: center;
  font-size: 0.75rem;
  color: var(--fastdo-dark-color);
}

.component address {
  font-style: normal;
  max-width: 500px;
  text-align: center;
  font-size: 0.75rem;
  color: var(--fastdo-dark-color);
}

.component address a {
  color: var(--fastdo-dark-color);
}

.dataProtectionLink {
  text-decoration: none;
  font-size: 0.75rem;
  margin: 0;
  color: var(--fastdo-primary-color-light);
}

.cookiesSettingsP {
  font-size: 0.75rem;
  margin: 0;
  color: var(--fastdo-primary-color-light) !important;
  cursor: pointer;
}
