.component {
}

.loadingDiv {
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectorsBoxContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.selectorContainer {
  max-width: 500px;
  width: 50%;
  padding: 0 1rem;
}

.component p {
  color: var(--primary-black-color);
  text-align: start;
  font-size: 1rem;
  margin: 1.5rem 0 0.5rem 1rem;
}

.sliderContainer {
  max-width: 500px;
  margin: auto;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonItem {
  padding: 0.5rem 0.5rem;
}

@media (max-width: 850px) {
  .selectorContainer {
    width: 100%;
  }
  .sliderContainer {
    padding: 0 1rem;
  }
}

@media (max-width: 500px) {
  .buttonsContainer {
    flex-direction: column;
  }
}
