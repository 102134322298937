.component {
  display: flex;
  align-items: center;
  padding: 6rem 10%;
}

.componentImageLeft {
  background-color: var(--fastdo-secondary-color);
  color: var(--fastdo-light-color);
}

.componentImageRight {
  background-color: var(--fastdo-primary-background-color);
  color: var(--fastdo-dark-color);
}

.innerDiv {
  display: flex;
  align-items: center;
}

.componentImageRight .innerDiv {
  flex-direction: row-reverse;
}

.innerDiv img {
  max-width: 350px;
  width: 100%;
}

.componentImageLeft .textDiv {
  margin-left: 3rem;
}

.componentImageRight .textDiv {
  margin-right: 3rem;
}

.componentImageLeft .title {
  color: var(--fastdo-primary-color-light);
}

.componentImageRight .title {
  color: var(--fastdo-primary-color);
}

.componentImageLeft .text {
  color: var(--fastdo-light-color);
}

.textDiv {
  max-width: 600px;
}

@media (min-width: 1510px) {
  .innerDiv {
    margin: auto;
  }
  .componentImageLeft .innerDiv {
    padding-right: 220px;
  }
  .componentImageRight .innerDiv {
    padding-left: 220px;
  }
}

@media (max-width: 1000px) {
  .innerDiv img {
    max-width: 300px;
  }
}

@media (max-width: 900px) {
  .component {
    padding: 0;
  }
  .textDiv h2 {
    margin-top: 0;
  }
  .componentImageRight .innerDiv {
    flex-direction: column;
  }
  .innerDiv {
    flex-direction: column;
    margin: auto;
    padding: 2rem 10%;
  }
  .innerDiv img {
    margin: -1rem 0 0 0;
    max-width: 60%;
  }
  .componentImageLeft .textDiv {
    margin-left: 0;
  }
  .componentImageRight .textDiv {
    margin-right: 0;
  }
}

@media (max-width: 550px) {
  .textDiv p {
    text-align: left;
  }
  .innerDiv {
    padding: 2rem 2rem;
  }
}
