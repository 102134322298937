.component {
  display: flex;
  max-width: 100%;
  box-shadow: var(--fastdo-article-card-box-shadow);
  border-radius: var(--fastdo-article-card-border-radius);
  overflow: hidden;
  cursor: pointer;
  background: var(--fastdo-article-card-background);
  max-height: var(--fastdo-article-card-max-height);
}

.imgDiv {
  width: var(--fastdo-article-card-img-width);
}

.imgDiv img {
  float: left;
  width: var(--fastdo-article-card-img-width);
  min-height: 100%;
}

.textDiv {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.textDiv h2 {
  text-align: center;
}

.textDiv p {
  text-align: start;
}

@media (max-width: 800px) {
  .component {
    flex-direction: column;
  }
  .imgDiv {
    width: 100%;
    max-height: 50vh;
    background: transparent;
    background-image: none !important;
  }
  .imgDiv img {
    display: none;
    width: 100%;
  }
  .textDiv {
    width: 100%;
    padding: 0 2rem;
  }
  .textDiv h2 {
    margin: 1rem 0;
  }
}
