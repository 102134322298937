.fastdo-button {
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-weight: var(--fastdo-button-font-weight);
  font-size: var(--fastdo-button-font-size);
  padding: var(--fastdo-button-padding);
  border-radius: var(--fastdo-button-border-radius);
  transition: 0.3s;
  display: inline-block;
}

.fastdo-button-default {
  background: var(--fastdo-button-background, var(--fastdo-primary-color));
  color: var(--fastdo-button-color, var(--fastdo-light-color));
  border: var(--fastdo-button-border, 2px solid var(--fastdo-primary-color));
}

.fastdo-button-default:hover {
  background: var(--fastdo-button-background-hover, var(--fastdo-primary-color));
  color: var(--fastdo-button-color-hover, var(--fastdo-light-color));
  border: var(--fastdo-button-border-hover, 2px solid var(--fastdo-primary-color));
}

.fastdo-button-default:focus {
  background: var(--fastdo-button-background-focus);
  color: var(--fastdo-button-color-focus, var(--fastdo-light-color));
  border: var(--fastdo-button-border-focus, 2px solid var(--fastdo-primary-color));
  box-shadow: var(--fastdo-button-box-shadow-focus, var(--fastdo-box-shadow-focus-1));
}

.fastdo-button-disabled {
  border: var(--fastdo-button-disabled-border, 2px solid var(--fastdo-gray3-color));
  background: var(--fastdo-button-disabled-background, var(--fastdo-gray3-color));
  color: var(--fastdo-button-disabled-color, var(--fastdo-light-color));
}

.fastdo-button-disabled-outlined {
  border: 2px solid var(--fastdo-gray3-color);
  background: var(--fastdo-light-color);
  color: var(--fastdo-gray3-color);
}

.fastdo-button-outlined {
  border: var(--fastdo-button-border, 2px solid var(--fastdo-primary-color));
  background: var(--fastdo-light-color);
  color: var(--fastdo-primary-color);
}

.fastdo-button-outlined:hover {
  background: var(--fastdo-primary-color);
  border-color: var(--fastdo-primary-color);
  color: var(--fastdo-light-color);
}

.fastdo-button-outlined:focus {
  background: var(--fastdo-primary-color);
  border-color: var(--fastdo-primary-color);
  color: var(--fastdo-light-color);
  box-shadow: var(--fastdo-button-box-shadow-focus, var(--fastdo-box-shadow-focus-1));
}

.fastdo-button-danger {
  border: 2px solid var(--fastdo-error-color);
  background: var(--fastdo-error-color);
  color: var(--fastdo-light-color);
}

.fastdo-button-danger:hover {
  background: var(--fastdo-error-dark-color);
  border-color: var(--fastdo-error-dark-color);
}

.fastdo-button-danger:focus {
  border: 2px solid var(--fastdo-error-color);
  background: var(--fastdo-error-color);
  box-shadow: var(--fastdo-error-box-shadow-focus-1);
}

.fastdo-button-danger-outlined {
  border: 2px solid var(--fastdo-error-color);
  background: var(--fastdo-light-color);
  color: var(--fastdo-error-color);
}

.fastdo-button-danger-outlined:hover {
  background: var(--fastdo-error-color);
  border-color: var(--fastdo-error-color);
  color: var(--fastdo-light-color);
}

.fastdo-button-danger-outlined:focus {
  background: var(--fastdo-error-color);
  border-color: var(--fastdo-error-color);
  color: var(--fastdo-light-color);
  box-shadow: var(--fastdo-error-box-shadow-focus-1);
}
