.component {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  margin: 0;
  color: var(--primary-text-color);
}

.icon {
  height: 30px;
  width: 30px;
  cursor: pointer;
  color: var(--primary-black-color);
}
