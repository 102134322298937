.contentDiv ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.itemHeader {
  white-space: nowrap;
}

.itemText {
  text-align: end;
}
