.publicationsDiv {
  text-align: start;
}

.publicationsDiv a {
  color: var(--fastdo-primary-color);
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
  position: relative;
  margin-bottom: 4px;
}

.publicationsDiv a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--fastdo-primary-color);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.publicationsDiv a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
