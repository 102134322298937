.component {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem;
  border: none;
}

.labelP {
  margin: 0;
}

.labelDiv {
  display: flex;
  justify-content: space-between;
}

.aLabelP {
  margin: 0;
  color: #e76f51;
}

.aLabelValueP {
  margin: 0;
  margin-left: 1rem;
  color: #e76f51;
}

.bLabelP {
  margin: 0;
  color: #264653;
}

.bLabelValueP {
  margin: 0;
  margin-left: 1rem;
  color: #264653;
}

@media (max-width: 550px) {
  .component p {
    font-size: 1rem;
  }
}
