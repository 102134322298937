.defaultTr {
  cursor: pointer;
  z-index: 1;
}

.defaultTr:hover {
  background-color: var(--fastdo-primary-color);
  color: var(--fastdo-light-color);
}

.defaultTr td {
  padding: 0.6rem 1rem;
  text-align: start;
}

.extendTrContainerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}

.extendTrContainerDiv div {
  padding: 0 1rem;
}

@media (max-width: 600px) {
  .defaultTr td {
    padding: 0.6rem 0.5rem;
    font-size: 0.8rem;
  }
}
