.component {
  min-width: 100%;
}

.commandsDiv {
  max-width: 500px;
  padding: 0 1rem;
  margin: auto;
}

.filterTextDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterText {
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.1rem;
  text-decoration: underline;
}

.filerCardDiv {
  max-width: 1000px;
  margin: auto;
}

.paginationDiv {
  display: flex;
  justify-content: center;
}

.tableDiv {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 1rem 1rem 2rem 1rem;
  color: var(--primary-black-color);
}

.table {
  border-collapse: collapse;
  font-size: 0.9rem;
  margin: auto;
}

.table th {
  padding: 1rem 1rem;
  text-align: start;
}

.loadingContainer {
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .table th {
    padding: 0.6rem 0.5rem;
    font-size: 0.8rem;
  }
}
