.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.logo {
  height: 80px;
}

.icon {
  width: 130px;
  height: 130px;
  color: var(--primary-black-color);
}

.text {
  max-width: 500px;
  text-align: center;
  padding: 0 1rem;
}
