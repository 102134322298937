.component {
  text-align: start;
  display: flex;
  flex-direction: column;
}

.component label {
  font-size: var(--fastdo-label-font-size);
  font-weight: var(--fastdo-label-font-weight);
  margin-left: 12px;
}

.component select {
  margin-top: 6px;
  font-size: var(--fastdo-body1-font-size);
  font-weight: var(--fastdo-body1-font-weight);
  padding: 10px 12px;
  border: 0;
  border-radius: var(--fastdo-border-radius-2);
  width: 100%;
  transition: 0.3s;
  color: var(--fastdo-dark-color);
  background-color: var(--fastdo-gray1-color);
  cursor: pointer;
}

.component select:focus {
  outline: none;
  box-shadow: var(--fastdo-box-shadow-focus-2);
}
