.component {
  width: 100%;
}

.contentDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--primary-text-item-padding);
}

.contentDiv p {
  color: var(--primary-text-color);
  margin: 0;
}
