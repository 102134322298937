.component {
  max-width: var(--fastdo-home-layout-max-width);
  margin: auto;
  padding: 0 1rem;
  text-align: center;
}

@media (min-width: 800px) {
  .component h1 {
    font-size: 2.7rem;
  }
}

@media (max-width: 650px) {
  .component h1 {
    font-size: 2.2rem;
  }
}

@media (max-width: 500px) {
  .component h1 {
    font-size: 2rem;
  }
}
