@media (min-width: 1100px) {
  .component {
    transform: translateX(calc((max(-90vw, -1300px) + var(--fastdo-home-layout-max-width)) / 2));
    width: 90vw;
    max-width: 1300px;
  }
}

.textContainer {
  margin: auto;
  max-width: var(--fastdo-home-layout-max-width);
}

.componentContent {
  display: flex;
}

.settingsContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sliderDiv {
  padding: 0 1rem;
}

.investmentLengthLabelDiv {
  display: flex;
  justify-content: center;
}

.graphContainer {
  width: 70%;
  padding-left: 1rem;
}

.settingsLabelP {
  font-size: var(--fastdo-text-field-label-font-size, var(--fastdo-label-font-size));
  font-weight: var(--fastdo-text-field-label-font-weight, var(--fastdo-label-font-weight));
  margin: var(--fastdo-text-field-label-margin, 0 0 0 12px);
}

@media (max-width: 950px) {
  .componentContent {
    flex-direction: column;
  }
  .settingsContainer {
    width: 100%;
  }
  .graphContainer {
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 550px) {
  .headerContainer {
    padding: 1rem 2rem;
  }
  .graphContainer {
    width: 100%;
    padding: 1rem 0.5rem;
  }
  .component {
    padding: 0;
  }
}

.graphLegendContainer {
  display: flex;
  justify-content: center;
}

.graphLegendItemDiv {
  display: flex;
  align-items: center;
  justify-content: left;
}

.graphLegendItemDiv hr {
  border: none;
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 35px;
  margin: 0;
  margin-right: 0.5rem;
}

.graphLegendItemDiv p {
  margin: 0.1rem;
  font-size: 1rem;
}
