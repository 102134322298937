.component {
  display: flex;
}

.pictureRight {
  flex-direction: row-reverse;
}

.imgBox {
  margin: auto;
  height: 400px;
}

.imgBox img {
  cursor: pointer;
  height: 100%;
  border-radius: 16px;
}

.textBox {
  margin: auto;
}

.textLeft {
  padding-right: 36px;
}

.textRight {
  padding-left: 36px;
}

@media (max-width: 800px) {
  .component {
    flex-direction: column;
  }
  .imgBox {
    width: 100%;
    height: 300px;
    margin-bottom: 16px;
  }
  .textLeft {
    padding: 0;
  }
  .textRight {
    padding: 0;
  }
}
