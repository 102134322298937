.containerDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsDiv {
  display: flex;
}

.actionIcon {
  height: 26px;
  width: 26px;
  cursor: pointer;
  color: var(--fastdo-dark-color);
}

.loadingContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 16px;
}

@media (max-width: 600px) {
  .containerDiv {
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
  }
  .mobileSpacerDiv {
    height: 1rem;
  }
}
