.component {
    display: flex;
    justify-content: space-between;
}

.halfContainer {
    width: 50%;
}

.leftContainer {
    margin-right: 0.5rem;
}

.rightContainer {
    margin-left: 0.5rem;
}

@media (max-width: 1000px) {
    .component {
        flex-direction: column;
        justify-content: start;
    }

    .halfContainer {
        width: 100%;
    }

    .leftContainer {
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .rightContainer {
        margin-left: 0;
    }
}

@media (max-width: 500px) {
    .component {
        padding: 0;
    }
}
