.descriptionDiv {
  max-width: var(--fastdo-home-layout-max-width);
  width: 100%;
  padding: 0 1rem;
  margin: auto;
  margin-bottom: 16px;
}

.commandsDiv {
  max-width: 500px;
  padding: 0 1rem;
  margin: auto;
}

.scatterDiv {
  min-height: 630px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customTooltip_Container {
  border-radius: 16px;
  background-color: var(--fastdo-light-color);
  opacity: 1;
  padding: 1rem;
  max-width: 100%;
}

.customTooltip_itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0;
}

.customTooltip_itemTitle {
  margin-right: 10px;
}

@media (max-width: 600px) {
  .customTooltip_Container {
    max-width: 300px;
  }
  .customTooltip_itemContainer {
    flex-direction: column;
    align-items: flex-start;
  }
}

.xAxisLabelsContainer {
  width: 100%;
  padding: 0 1.4rem 0 4.2rem;
}

.ticksContainer {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}

.ticksContainer div {
  border: 2px solid #000000;
  color: #ffffff;
  padding: 0.4rem 1rem;
  text-align: center;
}

@media (max-width: 600px) {
  .ticksContainer div {
    padding: 0.2rem 0.4rem;
  }
}
