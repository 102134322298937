.textFieldSelectionDiv {
  display: flex;
}

.textFieldSelectionLeftDiv {
  width: 50%;
  padding: 0 1rem 0 0;
}

.textFieldSelectionRightDiv {
  width: 50%;
  padding: 0 0 0 1rem;
}

.textFieldSelectionLabelDiv {
  text-align: center;
}

@media (max-width: 950px) {
  .textFieldSelectionDiv {
    flex-direction: column;
  }
  .textFieldSelectionLeftDiv {
    width: 100%;
    padding: 0;
  }
  .textFieldSelectionRightDiv {
    margin-top: 1rem;
    width: 100%;
    padding: 0;
  }
}
