.component {
  padding: 6rem 0 0 0;
  min-height: 100vh;
}

@media (max-width: 800px) {
  .titleSectionHeaderDiv {
    max-width: 500px;
  }

  .titleSectionHeaderDiv h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 550px) {
  .titleSectionHeaderDiv {
    max-width: 400px;
  }

  .titleSectionHeaderDiv h1 {
    font-size: 2rem;
  }
}

.titleCommandsContainerDiv {
  max-width: 500px;
  padding: 0 1rem;
  margin: auto;
}

.extendedSearchP {
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.1rem;
  text-decoration: underline;
}

.filerTabContainerDiv {
  max-width: 1000px;
  margin: auto;
}

.paginationContainerDiv {
  display: flex;
  justify-content: center;
}

.sectionContainerDivOdd {
  background-color: var(--fastdo-secondary-color);
  color: var(--fastdo-light-color);
}

.sectionContainerDivEven {
  background-color: var(--fastdo-primary-background-color);
  color: var(--fastdo-dark-color);
  flex-direction: row-reverse;
}

.sectionContainerDiv {
  display: flex;
  align-items: center;
  padding: 6rem 10%;
}

.sectionContainerInnerDiv {
  display: flex;
  align-items: center;
}

.sectionContainerDivEven .sectionContainerInnerDiv {
  flex-direction: row-reverse;
}

.sectionContainerDiv img {
  max-width: 350px;
  width: 100%;
}

.sectionContainerDivOdd .sectionTextDiv {
  margin-left: 3rem;
}

.sectionContainerDivEven .sectionTextDiv {
  margin-right: 3rem;
}

.sectionContainerDivOdd h2 {
  color: var(--fastdo-primary-color-light);
}

.sectionContainerDivEven h2 {
  color: var(--fastdo-primary-color);
}

.sectionContainerDivOdd p {
  color: var(--fastdo-light-color);
}

.sectionContainerDivOdd .mailLink {
  color: var(--fastdo-light-color);
}

.sectionContainerDivEven .mailLink {
  color: var(--fastdo-dark-color);
}

.mailLink {
  text-decoration: none;
}

.mailLink:hover {
  color: var(--fastdo-light-color);
}

.sectionTextDiv {
  max-width: 600px;
}

.sectionTextDiv h2 {
  font-size: 2.1rem;
}

.sectionTextDiv p {
  text-align: justify;
}

@media (max-width: 1000px) {
  .sectionContainerDiv img {
    max-width: 300px;
  }
}

@media (max-width: 900px) {
  .sectionTextDiv h2 {
    margin-top: 0;
  }

  .sectionContainerDivEven .sectionContainerInnerDiv {
    flex-direction: column;
  }

  .sectionContainerDiv {
    padding: 0;
  }

  .sectionContainerInnerDiv {
    flex-direction: column;
    margin: auto;
    padding: 2rem 10%;
  }

  .sectionContainerDiv img {
    margin: -1rem 0 0 0;
    max-width: 60%;
  }

  .sectionContainerDivOdd .sectionTextDiv {
    margin-left: 0;
  }

  .sectionContainerDivEven .sectionTextDiv {
    margin-right: 0;
  }
}

@media (max-width: 550px) {
  .sectionTextDiv p {
    text-align: left;
  }

  .sectionContainerInnerDiv {
    padding: 2rem 2rem;
  }
}

@media (min-width: 1510px) {
  .sectionContainerInnerDiv {
    margin: auto;
  }

  .sectionContainerDivOdd .sectionContainerInnerDiv {
    padding-right: 220px;
  }

  .sectionContainerDivEven .sectionContainerInnerDiv {
    padding-left: 220px;
  }
}
