.component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0;
}

.contentDiv {
  width: 100%;
  max-width: 380px;
}
